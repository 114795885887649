import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as yup from 'yup'

import { useBloodMode } from 'Components/BloodMode'
import Field from 'Components/Field'
import FieldErrors from 'Components/FieldErrors'
import Input from 'Components/Input'
import Loader from 'Components/Loader'

import { ApiError, get, post } from 'Helpers/api'

type Account = string
type Address = string | null

type SignedMessage =
  | {
      message: string
      signature: string
    }
  | any

interface Props {
  address: Address
  signedAccount: SignedMessage
  goBack: () => void
}

type CheckAccountFormData = {
  account: Account
}

type ClaimAccountFormData = {
  email: string
}

export default function Claim({ address, signedAccount, goBack }: Props) {
  const queryClient = useQueryClient()

  const reset = async () => {
    await queryClient.invalidateQueries('check-account')
    goBack()
  }

  return (
    <div className="mt-12 p-6 lg:px-10 lg:py-12 bg-white border rounded-md">
      <button
        type="button"
        onClick={reset}
        className="inline-flex items-center px-2.5 py-1.5 mb-6 border border-transparent text-xs font-medium text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        Back
      </button>
      <CheckAccount {...{ address, signedAccount, reset }} />
    </div>
  )
}

function CheckAccount({
  address,
  signedAccount
}: {
  address: Address
  signedAccount: SignedMessage
}) {
  const { active } = useBloodMode()

  const FormSchema = yup.object().shape({
    account: yup.string().required('Account required')
  })

  const form = useForm<CheckAccountFormData>({
    resolver: yupResolver(FormSchema)
  })

  const {
    watch,
    formState: { errors },
    handleSubmit
  } = form

  const account = watch('account')

  const {
    isLoading: isChecking,
    isSuccess,
    isError,
    error,
    refetch: check
  } = useQuery<AccountResponse, ApiError>(
    'check-account',
    get(`hedera/${account}`),
    {
      cacheTime: 0,
      enabled: false
    }
  )

  const onSubmit = handleSubmit(() => check())

  const {
    mutateAsync: claimNotification,
    isLoading: isClaiming,
    isSuccess: isSuccessNotificationClaim,
    isError: isErrorNotificationClaim
  } = useMutation<ClaimResponse, ApiError, ClaimNotificationRequest>(
    'redeem-notification',
    post(`addresses/${address}/notification`)
  )

  const onNotificationClaim = handleSubmit(async () => {
    try {
      await claimNotification({
        hts_address: account,
        signature: signedAccount.signature,
        message: signedAccount.message
      })
    } catch (error) {
      // Error is already handled by the useMutation hook
      console.log(error)
    }
  })

  if (isChecking || isClaiming) {
    return <Loader />
  }

  if (isErrorNotificationClaim) {
    return (
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Unable to claim tokens
        </h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <p>
              {
                "We're really sorry but we were unable to complete the claim. Please try again or contact us if the problem persists."
              }
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (isSuccessNotificationClaim) {
    return (
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex items-top">
          <div className="flex-shrink-0">
            <svg
              className="h-6 w-6 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-medium text-blue-500">
              Thank you for the confirmation, you should receive an email
              shortly on how to claim your DAO distribution tokens in the DOVU
              marketplace.
            </h3>
          </div>
        </div>
      </div>
    )
  }

  if (isSuccess) {
    return (
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Claim successful
        </h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <p>
              {
                'Congratulations, this Hedera ID has been associated with a staking account but has not been claimed.'
              }
            </p>
            <p className="mt-2">
              {
                'To confirm, click "Claim Account" and we\'ll send an email straight over to you!'
              }
            </p>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <button
              onClick={onNotificationClaim}
              className={classNames(
                'bg-blue-500 px-4 py-2 text-lg border border-transparent shadow-sm font-medium rounded-md text-white transition-colors ease-in-out duration-[3000ms] focus:outline-none sm:w-auto mt-2'
              )}
            >
              Claim Account
            </button>
          </div>
        </div>
      </div>
    )
  }

  if (isError && error?.response?.status === 404) {
    return <ClaimAccount {...{ address, account, signedAccount }} />
  }

  return (
    <>
      <div className="mb-3">
        <h3 className="text-2xl leading-6 font-medium text-gray-900">
          Account Availability
        </h3>
        <p className="mt-2 max-w-2xl text-gray-500">
          {
            "Provide your Hedera account below, if you haven't heard of the network you may create an account from a number of different wallets including "
          }
          <u>
            <a
              href="https://www.hashpack.app/"
              target="_blank"
              rel="noreferrer"
            >
              Hashpack
            </a>
          </u>
          {' and '}
          <u>
            <a href="https://www.venly.io/" target="_blank" rel="noreferrer">
              Venly
            </a>
          </u>
          .
          <br />
          <br />
          {
            "DOVU have committed to migrate all of our products and services to this network, we'd love for you to join us on the ride."
          }
          <br /> <br />
        </p>
      </div>
      <FormProvider {...form}>
        <div className="sm:flex sm:items-start">
          <div className="flex-1 flex items-center">
            <label htmlFor="account">Account</label>
            <div className="flex-1 ml-3">
              <Field name="account">
                <Input id="account" name="account" type="text" />
                <FieldErrors error={errors?.account} />
              </Field>
            </div>
          </div>

          <button
            onClick={onSubmit}
            className={classNames(
              'mt-3 w-full inline-flex items-center justify-center px-4 py-2 text-lg border border-transparent shadow-sm font-medium rounded-md text-white transition-colors ease-in-out duration-[3000ms] focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto',
              { 'bg-blood': active },
              { 'bg-blue-500 ': !active }
            )}
          >
            Check Availability
          </button>
        </div>
      </FormProvider>

      {isError && error?.response?.status === 422 && (
        <div className="rounded-md bg-red-50 p-4 mt-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="font-medium text-red-800">
                Unfortunately, this account has already been claimed.
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function ClaimAccount({
  address,
  account,
  signedAccount
}: {
  address: Address
  account: Account
  signedAccount: SignedMessage
}) {
  const { active } = useBloodMode()
  const {
    mutateAsync: claim,
    isLoading: isClaiming,
    isSuccess,
    isError
  } = useMutation<ClaimResponse, ApiError, ClaimRequest>(
    'redeem',
    post(`addresses/${address}/claim`)
  )

  const FormSchema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .email('Email must be valid')
  })

  const form = useForm<ClaimAccountFormData>({
    resolver: yupResolver(FormSchema)
  })

  const {
    formState: { errors },
    handleSubmit
  } = form

  const onSubmit = handleSubmit(async ({ email }: ClaimAccountFormData) => {
    try {
      await claim({
        email,
        hts_address: account,
        signature: signedAccount.signature,
        message: signedAccount.message
      })
    } catch (error) {
      // Error is already handled by the useMutation hook
      console.error(error)
    }
  })

  if (isClaiming) {
    return <Loader />
  }

  return (
    <>
      {!isSuccess ? (
        <>
          <div className="mb-3">
            <h3 className="text-2xl leading-6 font-medium text-gray-900">
              Claim your account
            </h3>
            <p className="mt-2 max-w-2xl text-gray-500">
              Please enter your preferred email below, you will receive an email
              on how to register to the DOVU marketplace And how you can claim
              your DAO distribution tokens.
            </p>
          </div>
          <FormProvider {...form}>
            <div className="sm:flex sm:items-start">
              <div className="flex-1 flex items-center">
                <label htmlFor="email">Email</label>
                <div className="flex-1 ml-3">
                  <Field name="email">
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                    />
                    <FieldErrors error={errors?.email} />
                  </Field>
                </div>
              </div>

              <button
                onClick={onSubmit}
                className={classNames(
                  'mt-3 w-full inline-flex items-center justify-center px-4 py-2 text-lg border border-transparent shadow-sm font-medium rounded-md text-white transition-colors ease-in-out duration-[3000ms] focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto',
                  { 'bg-blood': active },
                  { 'bg-blue-500 ': !active }
                )}
              >
                Claim Account
              </button>
            </div>
          </FormProvider>
        </>
      ) : (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <svg
                className="h-6 w-6 text-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-lg font-medium text-blue-500">
                Claim submitted succesfully
              </h3>
            </div>
          </div>
        </div>
      )}

      {isError && (
        <div className="rounded-md bg-red-50 p-4 mt-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                There was an error submitting your email. Please try again.
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
