import merge from 'lodash.merge'

import { API_URL } from 'Config'

type ServerError = {
  message: string
  errors?: { [key: string]: string[] }
}

export class ApiError extends Error {
  data?: ServerError
  response: Response

  constructor(response: Response, data?: any) {
    const message = `${response.status} ${response.statusText}`
    super(message)

    this.response = response

    if (data) {
      this.data = data
    }
  }
}

export async function request({
  endpoint,
  method = 'GET',
  body,
  config = {}
}: APIConfig) {
  const url = endpoint.startsWith('http') ? endpoint : `${API_URL}${endpoint}`

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }

  const defaultConfig: RequestInit = {
    method,
    headers: {
      ...headers
    }
  }

  if (body) {
    defaultConfig.body = JSON.stringify(body)
  }

  const response = await fetch(url, merge(defaultConfig, config))
  const contentType = response.headers.get('content-type')
  const data = contentType?.includes('application/json')
    ? await response.json()
    : await response.text()

  if (response.ok) {
    return data
  } else {
    throw new ApiError(response, data)
  }
}

export function get(endpoint: string, config?: RequestInit) {
  return () => {
    return request({ endpoint, method: 'GET', config })
  }
}

export function post(endpoint: string, config?: RequestInit) {
  return (variables?: any) => {
    return request({ endpoint, method: 'POST', body: variables, config })
  }
}

export function put(endpoint: string, config?: RequestInit) {
  return (variables?: any) => {
    return request({ endpoint, method: 'PUT', body: variables, config })
  }
}
