import { Switch } from '@headlessui/react'
import classNames from 'classnames'

import { useBloodMode } from 'Components/BloodMode'

import { BLOOD_MODE_LOCAL_STORAGE_KEY } from 'Config'

export default function ToggleBloodMode() {
  const { active, setActive } = useBloodMode()
  const seen = localStorage.getItem(BLOOD_MODE_LOCAL_STORAGE_KEY)

  if (seen) {
    return (
      <Switch
        checked={active}
        onChange={setActive}
        className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className="pointer-events-none absolute w-full h-full rounded-md"
        />
        <span
          aria-hidden="true"
          className={classNames(
            'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
            { 'bg-blood': active },
            { 'bg-gray-200': !active }
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
            { 'translate-x-5': active },
            { 'translate-x-0': !active }
          )}
        />
      </Switch>
    )
  }

  return null
}
