import classNames from 'classnames'

import { useBloodMode } from 'Components/BloodMode'
import Wallet from 'Components/Wallet'

import { SUPPORTED_WALLETS } from 'Config'

export default function WalletList() {
  const { active } = useBloodMode()
  return (
    <div className="mt-12 p-6 lg:px-10 lg:py-12 bg-white border rounded-md">
      <h3 className="text-2xl leading-6 font-medium text-gray-900">
        Connect with one of available wallet providers
      </h3>

      <div className="mt-6 space-y-6">
        {Object.keys(SUPPORTED_WALLETS).map((key) => {
          const option = SUPPORTED_WALLETS[key]

          return (
            <Wallet
              key={key}
              name={option.name}
              description={option.description}
              connector={option.connector}
            />
          )
        })}
      </div>

      <div
        className={classNames(
          'mt-6 rounded-md p-4 transition-colors ease-in-out duration-[3000ms]',
          { 'bg-blood': active },
          { 'bg-blue-500': !active }
        )}
      >
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className=" text-white">
              Please ensure that you are on the ETH Mainnet with MetaMask.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
