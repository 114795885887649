import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import * as yup from 'yup'

import { useBloodMode } from 'Components/BloodMode'
import Field from 'Components/Field'
import FieldErrors from 'Components/FieldErrors'
import Input from 'Components/Input'

import { trackEvent } from 'Helpers/analytics'
import { post } from 'Helpers/api'

type FormData = {
  address: string
  email: string
}

export default function Redeem({ address }: { address: string | null }) {
  const { active } = useBloodMode()
  const queryClient = useQueryClient()

  const form = useForm<FormData>({
    resolver: yupResolver(RedeemSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  })

  const {
    formState: { errors },
    handleSubmit
  } = form

  const { mutate, isLoading, isSuccess, isError } = useMutation<
    RedeemResponse,
    APIErrorResponse,
    RedeemRequest
  >('redeem', post(`addresses/${address}/subscribe`))

  const onSubmit = handleSubmit(async ({ address, email }) => {
    await mutate({ email, address })
    await queryClient.invalidateQueries(['redeem'])
    trackEvent({ category: 'Newsletter Signup', action: 'Success' })
  })

  return (
    <>
      {!isSuccess ? (
        <FormProvider {...form}>
          <div className="mt-5 sm:flex sm:items-start">
            <div className="w-full sm:max-w-xs">
              <label htmlFor="email" className="sr-only">
                Email
              </label>

              <Field name="email">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                />
                <FieldErrors error={errors?.email} />
              </Field>
            </div>

            <button
              onClick={onSubmit}
              className={classNames(
                'mt-3 inline-flex items-center justify-center px-4 py-2 text-lg border border-transparent shadow-sm font-medium rounded-md text-white transition-colors ease-in-out duration-[3000ms] focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto',
                { 'bg-blood': active },
                { 'bg-blue-500 ': !active }
              )}
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </FormProvider>
      ) : (
        <div className="mt-5 rounded-md bg-blue-50 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <svg
                className="h-6 w-6 text-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-lg font-medium text-blue-500">
                Email submitted succesfully
              </h3>
            </div>
          </div>
        </div>
      )}

      {isError && (
        <div className="rounded-md bg-red-50 p-4 mt-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                There was an error submitting your email. Please try again.
              </h3>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const RedeemSchema = yup.object().shape({
  email: yup.string().email().required('Please enter your email')
})
