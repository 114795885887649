function formatWeight(offset: number | undefined) {
  if (offset !== undefined && offset >= 1000) {
    return {
      weight: (offset / 1000).toFixed(4),
      suffix: 'tonnes'
    }
  } else {
    return {
      weight: offset?.toFixed(4),
      suffix: 'kg'
    }
  }
}

export default formatWeight
