import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { generateSignature } from './services/ethereum/metamask'
import { injected } from './web3/connectors'
import { useEagerConnect, useInactiveListener } from './web3/hooks'

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

// Need to return to this an check that metamask is installed and authorised.
function Web3Authorisation({ onMetamaskSubmit, children }) {
  const context = useWeb3React()
  // eslint-disable-next-line
  const { connector, library, account, activate } = context

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState()

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  useInactiveListener(!triedEager || !!activatingConnector)

  const onClickActivate = () => {
    setActivatingConnector(injected)

    activate(injected)
  }

  const signWithMetamask = () => {
    generateSignature({
      library,
      account,
      onSuccess: onMetamaskSubmit
    })
  }

  return (
    <div onClick={account ? signWithMetamask : onClickActivate}>{children}</div>
  )
}

function MetamaskSigner({ onMetamaskSubmit, children }) {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3Authorisation onMetamaskSubmit={onMetamaskSubmit}>
        {children}
      </Web3Authorisation>
    </Web3ReactProvider>
  )
}

MetamaskSigner.propTypes = {
  onMetamaskSubmit: PropTypes.func,
  children: PropTypes.node
}

Web3Authorisation.propTypes = {
  onMetamaskSubmit: PropTypes.func,
  children: PropTypes.node
}

export default MetamaskSigner
