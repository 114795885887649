export const API_URL = process.env.REACT_APP_API_URL
export const GOOGLE_ANALYTICS_CODE = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE

export const BLOOD_MODE_LOCAL_STORAGE_KEY = 'streets-have-turned-red'

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: 'injected',
    name: 'MetaMask',
    description: 'One of the most secure wallets with great flexibility.'
  }
}

export const SUPPORTED_SERVICES: { [key: string]: ServiceInfo } = {
  PANCAKESWAP: {
    url: process.env.REACT_APP_PANCAKESWAP_URL,
    name: 'Pancakeswap'
  },
  QUICKSWAP: {
    url: process.env.REACT_APP_QUICKSWAP_URL,
    name: 'Quickswap'
  },
  UNISWAP: {
    url: process.env.REACT_APP_UNISWAP_URL,
    name: 'Uniswap'
  }
}

export const getConnectors = () => {
  return Object.assign(
    {},
    ...Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]

      if (option.params) {
        return {
          [option.connector]: option.params
        }
      }

      return null
    })
  )
}
