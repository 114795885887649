import classNames from 'classnames'
import pluralize from 'pluralize'
import qs from 'qs'
import { Dispatch, SetStateAction } from 'react'
import { useQuery } from 'react-query'
import styled, { keyframes } from 'styled-components'

import { useBloodMode } from 'Components/BloodMode'
import Loader from 'Components/Loader'
import Redeem from 'Components/Redeem'

import { get } from 'Helpers/api'
import formatWeight from 'Helpers/formatWeight'

import { BLOOD_MODE_LOCAL_STORAGE_KEY } from 'Config'

import MetamaskSigner from '../MetamaskSigner'

interface Props {
  address: string | null
  isConfirming: boolean
  ownershipConfirmed: string | null
  onClaim: Dispatch<SetStateAction<boolean>>
}

export default function Account({
  address,
  isConfirming,
  ownershipConfirmed,
  onClaim
}: Props) {
  const { active, setActive } = useBloodMode()

  const {
    isLoading: isChecking,
    error: checkError,
    isError
  } = useQuery<EthereumAccountResponse, any, EthereumAccountRequest>(
    'eth-check',
    get(`ethereum/${address}`),
    {
      enabled: !!address
    }
  )

  const {
    isLoading: isOffsetting,
    error: offsetError,
    refetch,
    data
  } = useQuery<AccountResponse, APIErrorResponse>(
    'offset',
    get(`addresses/${address}/offset`),
    { enabled: !!address }
  )

  const displayWeight = (offset: number | undefined) => {
    const { weight, suffix } = formatWeight(offset)

    return (
      <>
        <span>{weight}</span>
        <span className="text-sm text-gray-900">{suffix}</span>
      </>
    )
  }

  const makeTheStreetsRunRed = () => {
    setActive(!active)
    localStorage.setItem(BLOOD_MODE_LOCAL_STORAGE_KEY, 'true')
  }

  const share = () => {
    const { weight, suffix } = formatWeight(data?.data.address_carbon_emissions)

    const payload = {
      text: `I’ve just calculated that my wallet has a crypto carbon debt of ${weight} ${suffix}.\rYou can too with the @dovuofficial carbon calculator ➡️ https://calculator.dovu.earth/\r\r`,
      hashtags: 'DOVU #CarbonOffset #CarbonCalculator $DOV'
    }

    const params = qs.stringify(payload)

    window.open(
      `https://twitter.com/intent/tweet/?${params}`,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    )
  }

  if (isChecking || isOffsetting) {
    return (
      <div className="mt-12 p-6 lg:px-10 lg:py-12 bg-white border rounded-md">
        <Loader />
      </div>
    )
  }

  if (offsetError) {
    return (
      <div className="rounded-md bg-red-50 p-4 mt-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-red-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blood">
              There was an error retrieving your details
            </p>

            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              <button
                onClick={() => refetch()}
                className="whitespace-nowrap font-medium text-blood"
              >
                Try again
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }

  const canClaim = (data?.data.dov_to_buy ?? 0) > 0 && active

  return (
    <>
      <div className="mt-12 p-6 lg:px-10 lg:py-12 bg-white border rounded-md">
        <dl className="space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-12">
          <div>
            <dt className="text-2xl font-normal text-gray-900">
              CO<sub>2</sub>e emissions
            </dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div
                className={classNames(
                  'flex items-baseline space-x-1 text-3xl font-semibold transition-colors ease-in-out duration-[3000ms]',
                  {
                    'text-blood': active,
                    'text-blue-500': !active
                  }
                )}
              >
                {displayWeight(data?.data.address_carbon_emissions)}
              </div>
            </dd>
          </div>

          <div>
            <dt className="text-2xl font-normal text-gray-900">
              DOV to offset
            </dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div
                className={classNames(
                  'flex items-baseline space-x-1 text-3xl font-semibold transition-colors ease-in-out duration-[3000ms]',
                  {
                    'text-blood': active,
                    'text-blue-500': !active
                  }
                )}
              >
                <span>{data?.data.dov_to_buy.toFixed(4)}</span>
                <span className="text-sm text-gray-900">DOV</span>
              </div>
            </dd>
          </div>

          <div>
            <dt className="text-2xl font-normal text-gray-900">
              Number of transactions
            </dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div
                className={classNames(
                  'flex items-baseline space-x-1 text-3xl font-semibold transition-colors ease-in-out duration-[3000ms]',
                  {
                    'text-blood': active,
                    'text-blue-500': !active
                  }
                )}
              >
                <span>{data?.data.address_transaction_count}</span>
                <span className="text-sm text-gray-900">
                  {pluralize(
                    'transaction',
                    data?.data.address_transaction_count
                  )}
                </span>
              </div>
            </dd>
          </div>

          <div>
            <dt className="text-2xl font-normal text-gray-900">Gas consumed</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div
                className={classNames(
                  'flex items-baseline space-x-1 text-3xl font-semibold transition-colors ease-in-out duration-[3000ms]',
                  {
                    'text-blood': active,
                    'text-blue-500': !active
                  }
                )}
              >
                <span>
                  {data?.data.address_gas_used.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  })}
                </span>
                <span className="text-sm text-gray-900">gas</span>
              </div>
            </dd>
          </div>
        </dl>

        <div className="flex tems-center justify-center mt-8 space-x-6">
          <div>
            <button className="group focus:outline-none" onClick={share}>
              <div
                className={classNames(
                  'inline-flex items-center justify-center space-x-2 px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out',
                  { 'group-hover:bg-blood': active },
                  { 'group-hover:bg-blue-500': !active }
                )}
              >
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="text-blue-500 group-hover:text-white transition-colors duration-300 ease-in-out"
                >
                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"></path>
                </svg>
                <span className="group-hover:text-white transition-colors duration-300 ease-in-out">
                  Share your results on Twitter
                </span>
              </div>
            </button>
          </div>

          <div className="flex items-center space-x-6">
            <button
              className="relative group overflow-hidden rounded-md focus:outline-none"
              onClick={makeTheStreetsRunRed}
            >
              {!active && (
                <Pulse className="absolute inline-flex h-full w-full bg-blood"></Pulse>
              )}
              <div
                className={classNames(
                  'inline-flex items-center justify-center space-x-2 px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out',
                  { 'group-hover:bg-blood': active },
                  { 'group-hover:bg-blue-500': !active }
                )}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-blood group-hover:text-white transition-colors duration-300 ease-in-out"
                >
                  <path
                    d="M17.25 14.0714C17.25 16.9315 14.8995 19.25 12 19.25C9.10051 19.25 6.75 16.9315 6.75 14.0714C6.75 11.2114 12 4.75 12 4.75C12 4.75 17.25 11.2114 17.25 14.0714Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="group-hover:text-white transition-colors duration-300 ease-in-out">
                  {active ? 'Disable' : 'Activate'} Blood Mode
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>

      {canClaim && (
        <div className="mt-6 bg-white border rounded-md hidden">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Claim Tokens
            </h3>
            <div
              className={classNames('mt-2', {
                'sm:flex sm:items-start sm:justify-between':
                  isError && checkError?.response?.status === 404
              })}
            >
              <div className="max-w-xl text-sm text-gray-500">
                <p>
                  {
                    'DOVU is committed to migrating all our products and services to the greenest DLT available - '
                  }
                  <u>
                    <a
                      href="https://hedera.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Hedera
                    </a>
                  </u>
                  {
                    ". If you hold DOV on Ethereum, we've made it simple for you to migrate these over to Hedera. We'd love for you to join us on this ride."
                  }
                </p>
              </div>
              {isError && checkError?.response?.status === 422 ? (
                <div className="mt-3 rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-blue-500">
                        Successfully claimed tokens!
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ownershipConfirmed === null && (
                  <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <MetamaskSigner onMetamaskSubmit={onClaim}>
                      <button className="group focus:outline-none">
                        <div
                          className={classNames(
                            'w-full inline-flex items-center justify-center px-4 py-2 text-lg border border-transparent shadow-sm font-medium rounded-md text-white transition-colors ease-in-out duration-[3000ms] focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto',
                            { 'bg-blood': active },
                            {
                              'bg-blue-500 ': !active
                            }
                          )}
                        >
                          <span className="group-hover:text-white transition-colors duration-300 ease-in-out">
                            {isConfirming ? 'Loading...' : 'Claim Tokens'}
                          </span>
                        </div>
                      </button>
                    </MetamaskSigner>
                  </div>
                )
              )}
            </div>
            {ownershipConfirmed !== null && (
              <div className="rounded-md bg-red-50 p-4 mt-3">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-red-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="font-medium text-red-800">
                      {ownershipConfirmed}
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="mt-6 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Sign up for the DOVU newsletter
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Change the email address you want associated with your account.
            </p>
          </div>
          <Redeem address={address} />
        </div>
      </div>
    </>
  )
}

const ping = keyframes`
  5% {
    opacity: 0.15;
  }
  10% {
    opacity: 0;
  }
`

const Pulse = styled.div`
  opacity: 0;
  animation: ${ping} 2s cubic-bezier(0, 0, 0.2, 1) infinite;
  animation-delay: 10s;
`
