/**
 * Metamask specific signing functionality for DOVU
 */

// This is static for now but would be looking to add nonce capability to reduce the risk of replay
const SIGN_DAO_MESSAGE =
  'The streets will turn blood red and screams will be our currency. 🩸🌕'

const messageWithSeed = () => {
  const seed = Math.floor(Math.random() * 10000000)

  return SIGN_DAO_MESSAGE + ' - ' + seed
}

export function generateSignature({
  library,
  account,
  onSuccess = console.log,
  onFailure = console.log
}) {
  const message = messageWithSeed()

  library
    .getSigner(account)
    .signMessage(message)
    .then((signature) => {
      onSuccess({ signature, account, message })
    })
    .catch((error) => {
      onFailure(
        'Failure!' + (error && error.message ? `\n\n${error.message}` : '')
      )
    })
}
