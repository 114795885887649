interface FieldProps {
  name?: string
  label?: string
  children: React.ReactNode
}

export default function Field({ name, label, children }: FieldProps) {
  return (
    <div>
      {label && name && (
        <label
          className="mb-1 text-xl font-normal text-gray-900"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div>{children}</div>
    </div>
  )
}
