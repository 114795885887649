import { useState } from 'react'
import { useMutation } from 'react-query'
import { useWallet } from 'use-wallet'

import Account from 'Components/Account'
import ToggleBloodMode from 'Components/BloodMode/Toggle'
import Claim from 'Components/Claim'
import Footer from 'Components/Footer'
import Header from 'Components/Header'
import Hero from 'Components/Hero'
import VerifiedBanner from 'Components/VerifiedBanner'
import WalletList from 'Components/WalletList'

import { post } from 'Helpers/api'

interface SignedAccount {
  signature: string
  message: string
}

function App() {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const verified = params.get('verified')
  const [account, setAccount] = useState<SignedAccount>({
    signature: '',
    message: ''
  })
  const [ownershipConfirmed, setOwnershipConfirmed] = useState<string | null>(
    null
  )
  const [claiming, setClaiming] = useState<boolean>(false)
  const [hidden, setHidden] = useState<boolean>(verified === null)
  const wallet = useWallet()

  const onClose = () => {
    window.history.pushState({}, document.title, window.location.pathname)
    setHidden(true)
  }

  const { mutateAsync: confirmAccount, isLoading: isConfirming } = useMutation<
    any,
    { errors: { ethereum: string } },
    SignedAccount
  >(post(`addresses/${wallet.account}/confirm`))

  const signClaimingMessage = async (account: any) => {
    console.log('We have paused the screams, one day we will return, more powerful than ever, the non-believers will tremble with fear. We are DOVU. xxx')

    return;

    // eslint-disable-next-line
    await setAccount(account)

    try {
      await confirmAccount(account)
      setOwnershipConfirmed(null)
      setClaiming(true)
    } catch (error: any) {
      setOwnershipConfirmed(error?.data.errors.ethereum)
    }
  };

  return (
    <div className="relative overflow-hidden">
      {!hidden && <VerifiedBanner onClose={onClose} />}

      <div className="absolute z-10 top-0 right-0 mt-3 mr-3">
        <ToggleBloodMode />
      </div>

      <section className="flex items-center justify-center relative py-20 bg-[#F4F2F3] min-h-screen">
        <div className="container">
          <div className="lg:grid lg:grid-cols-2">
            <div>
              <Hero />
            </div>

            <div className="relative col-start-2 w-full px-4 z-50">
              <Header />

              {wallet.status === 'connected' ? (
                <>
                  {claiming ? (
                    <Claim
                      address={wallet.account}
                      signedAccount={account}
                      goBack={() => setClaiming(!claiming)}
                    />
                  ) : (
                    <Account
                      address={wallet.account}
                      isConfirming={isConfirming}
                      ownershipConfirmed={ownershipConfirmed}
                      onClaim={signClaimingMessage}
                    />
                  )}
                </>
              ) : (
                <WalletList />
              )}

              <Footer />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default App
