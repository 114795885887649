import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react'

interface BloodModeProviderProps {
  children: React.ReactNode
}

interface BloodModeState {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
}

const BloodModeContext = createContext<BloodModeState>({
  active: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActive: () => {}
})

export function BloodModeProvider({ children }: BloodModeProviderProps) {
  const [active, setActive] = useState<boolean>(false)

  return (
    <BloodModeContext.Provider value={{ active, setActive }}>
      {children}
    </BloodModeContext.Provider>
  )
}

export function useBloodMode() {
  return useContext(BloodModeContext)
}
