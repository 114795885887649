import classNames from 'classnames'
import { useWallet } from 'use-wallet'

import { useBloodMode } from 'Components/BloodMode'
import MetaMask from 'Components/Icon/MetaMask'

import { trackEvent } from 'Helpers/analytics'

const ICONS: { [key: string]: React.FunctionComponent<any> } = {
  MetaMask: MetaMask
}

export default function Wallet({ name, description, connector }: WalletInfo) {
  const { active } = useBloodMode()
  const wallet = useWallet()
  const Icon = ICONS[name]

  const connect = () => {
    wallet.connect(connector)
    trackEvent({ category: 'Wallet', action: 'Connected', label: name })
  }

  return (
    <button
      aria-label="connect"
      onClick={connect}
      className={classNames(
        'w-full flex space-x-6 text-left border-4 border-gray-200 p-6 rounded-md transition-colors duration-300 ease-in-out focus:outline-none',
        { 'hover:border-blood': active },
        { 'hover:border-blue-500': !active }
      )}
    >
      <Icon />
      <div className="flex-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{name}</h3>
        <p className="mt-1 max-w-2xl text-gray-500">{description}</p>
      </div>
    </button>
  )
}
