import { useEffect } from 'react'
import ReactGA from 'react-ga'

import { GOOGLE_ANALYTICS_CODE } from 'Config'

const useAnalytics = () => {
  useEffect(() => {
    if (!window.location.href.includes('localhost') && GOOGLE_ANALYTICS_CODE) {
      ReactGA.initialize(GOOGLE_ANALYTICS_CODE)
    }
  }, [])
}

export default useAnalytics
