type ErrorType = 'required' | 'maxLength' | 'minLength' | 'max' | 'min'

interface FieldErrorsProps {
  error: any
}

function formatError(name: string, type: ErrorType): string {
  return `${name} ${type}`
}

export default function FieldErrors({ error }: FieldErrorsProps) {
  if (error) {
    let message = error.message

    if (message === '') {
      message = formatError(error.ref.name, error.type)
    }

    return (
      <p className="mt-2 text-blood" id="email-error">
        {message}
      </p>
    )
  }

  return null
}
