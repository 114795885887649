import classNames from 'classnames'
import styled, { keyframes } from 'styled-components'

import { useBloodMode } from 'Components/BloodMode'
import Moon from 'Components/Moon'

import Clouds from 'Assets/Images/clouds.png'
import EmptyHand from 'Assets/Images/empty_hand.jpg'
import Montage from 'Assets/Images/montage.jpg'

export default function Hero() {
  const { active } = useBloodMode()

  return (
    <>
      <Layer hidden={!active}>
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2 w-1/2">
          <img className="hidden h-auto lg:block" src={EmptyHand} alt="" />
          <div className="hidden absolute top-0 left-0 w-full h-full lg:block">
            <div className="w-[65%] absolute bottom-[27%] left-[27%]">
              <Moon />
            </div>
          </div>
        </div>
        <StyledClouds className="w-screen absolute top-1/2 left-0 transform -translate-y-1/2 z-10" />
      </Layer>

      <Layer hidden={active}>
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2 w-1/2">
          <img className="hidden h-auto lg:block" src={Montage} alt="" />
        </div>
      </Layer>
    </>
  )
}

function Layer({
  hidden,
  children
}: {
  hidden: boolean
  children: React.ReactNode
}) {
  const classes = classNames(
    'transition-opacity ease-in-out duration-[3000ms]',
    {
      'opacity-0': hidden,
      'opacity-100': !hidden
    }
  )

  return <div className={classes}>{children}</div>
}

const moveClouds = keyframes`
  from {background-position:0 0;}
  to {background-position:10000px 0;}
`

const StyledClouds = styled.div`
  height: 669px;
  background-image: url(${Clouds});
  animation: ${moveClouds} 200s linear infinite;
`
