import { useBloodMode } from 'Components/BloodMode'

export default function Header() {
  const { active } = useBloodMode()

  return (
    <>
      <svg
        className="w-16 mb-12"
        viewBox="0 0 274 273"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path
            style={{
              transition: 'fill ease-in-out 1s'
            }}
            d="M137 272.774c75.263 0 136.274-61.01 136.274-136.273C273.274 61.237 212.263.226 137 .226 61.736.226.726 61.236.726 136.5c0 75.263 61.01 136.273 136.274 136.273"
            fill={active ? '#880808' : '#000'}
          />
          <g fill="#FFFFFE">
            <path
              style={{
                transition:
                  'transform ease-in-out 1s, opacity ease-in-out 0.5s 0.2s',
                transformOrigin: 'center',
                transform: active ? 'rotate(360deg)' : 'rotate(0)'
              }}
              d="M135.729 41.273c18.7.187 34.867 4.513 49.793 13.325 3.129 1.843 4.795 5.176 4.299 8.344-.585 3.752-2.947 6.472-6.48 7.289-2.2.507-4.325.198-6.3-.971-9.408-5.59-19.555-9.116-30.41-10.385-18.737-2.185-36.246 1.595-52.048 11.963-18.933 12.415-30.592 29.928-34.576 52.237-3.343 18.69.006 36.357 9.49 52.817 1.607 2.78 2.098 5.573.746 8.498-1.434 3.1-3.984 4.794-7.388 4.905-3.272.11-5.855-1.308-7.565-4.14-4.155-6.901-7.494-14.196-9.596-21.971-8.773-32.478-2.71-62.01 18.673-87.965 14.749-17.911 33.973-28.655 56.922-32.556 1.804-.31 3.614-.634 5.44-.79 3.454-.291 6.92-.468 9-.6M232.185 135.36c.017 44.855-29.697 83.01-72.455 93.5-24.234 5.948-47.387 2.56-69.282-9.391-3.217-1.76-5.054-5.303-4.563-8.52.568-3.73 3.068-6.5 6.676-7.31 2.169-.487 4.216-.08 6.152 1.02 8.995 5.07 18.646 8.227 28.875 9.452 13.707 1.645 27.06.017 39.708-5.556 25.784-11.362 41.482-31.044 46.686-58.7 3.404-18.11.22-35.375-8.663-51.548-2.49-4.53-1.21-9.573 3.062-11.951 4.254-2.373 9.237-.756 11.753 3.752 6.738 12.068 10.589 25.012 11.742 38.763.21 2.467.237 4.949.309 6.489"
            />
          </g>
          <path
            style={{
              transition: 'transform ease-in-out 1s',
              transform: active ? 'translateY(8px)' : 'translateY(0)'
            }}
            d="M137.082 79.387c13.975 18.792 23.084 32.017 27.293 39.695 4.085 7.45 6.18 14.614 6.13 21.526l-.01.619c.01 9.267-3.74 17.658-9.812 23.73-6.062 6.061-14.437 9.811-23.688 9.811s-17.626-3.75-23.688-9.812c-6.063-6.062-9.812-14.437-9.812-23.688 0-6.688 1.958-12.92 5.336-18.148.573-1.057.885-1.647 1.21-2.24 4.11-7.532 13.131-21.36 27.041-41.493Z"
            stroke="#FFF"
            strokeWidth="11"
          />
          <g stroke="#FFF" strokeLinecap="round" strokeWidth="10">
            <path
              style={{
                transition:
                  'stroke-dashoffset ease-in-out 1s, stroke-dasharray ease-in-out 1s'
              }}
              strokeDasharray={active ? 190 : 0.1}
              strokeDashoffset={active ? 190 : 0.1}
              d="M136.898 78.392V198M116.447 110.623l19.915 19.86M107.904 128.693l28.526 30.443"
            />
          </g>
        </g>
      </svg>

      <h2 className="mb-10 text-6xl font-semibold font-heading">
        Capture your carbon and support rural communities
      </h2>
      <p className="text-xl text-gray-500 leading-relaxed">
        Dovu is making it easy to offset your crypto carbon by harnessing the
        incredible power of the soil. Our partnering carbon farmers can work
        their magic.
      </p>
    </>
  )
}
