import styled, { keyframes } from 'styled-components'

import MoonMap from 'Assets/Images/moonmap_red.jpg'

export default function Moon() {
  return (
    <div className="aspect-w-1 aspect-h-1">
      <div className="absolute w-full h-full overflow-hidden rounded-full">
        <StyledMoon className="w-full h-full" />
        <div
          className="absolute w-full h-full top-0 right-0 bottom-0 left-0 border-black rounded-full"
          style={{
            boxShadow: '0 0 10px 5px rgba(0,0,0,0.5) inset, 0 0 20px 20px'
          }}
        />
        <div
          className="absolute w-full h-full top-0 right-0 bottom-0 left-0 border-black rounded-full"
          style={{ boxShadow: '-40px -40px 50px 2px #000 inset' }}
        />
      </div>
    </div>
  )
}

const spin = keyframes`
  to {
    background-position: 144% 0;
  }
`

const StyledMoon = styled.div`
  background-image: url(${MoonMap});
  background-size: cover;
  animation: ${spin} 240s linear infinite;
`
