import { QueryClient, QueryClientProvider } from 'react-query'
import { UseWalletProvider } from 'use-wallet'

import App from 'Components/App'
import { BloodModeProvider } from 'Components/BloodMode'

import useAnalytics from 'Hooks/useAnalytics'

import { getConnectors } from 'Config'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false
    }
  }
})

export default function AppProvider() {
  useAnalytics()

  return (
    <QueryClientProvider client={queryClient}>
      <UseWalletProvider chainId={1} connectors={getConnectors()}>
        <BloodModeProvider>
          <App />
        </BloodModeProvider>
      </UseWalletProvider>
    </QueryClientProvider>
  )
}
