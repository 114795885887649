import classNames from 'classnames'
import { InputHTMLAttributes, forwardRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useBloodMode } from 'Components/BloodMode'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Input = forwardRef(({ name, value, ...rest }: InputProps, _ref) => {
  const { active } = useBloodMode()

  return (
    <div className="relative rounded-lg shadow-sm">
      <input
        name={name}
        value={value}
        {...rest}
        className={classNames(
          'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 text-lg focus:outline-none',
          {
            'focus:ring-blood focus:border-blood': active
          },
          { 'focus:ring-blue-500 focus:border-blue-500 ': !active }
        )}
      />
    </div>
  )
})

Input.displayName = 'Input'

interface ControllerInputProps extends InputProps {
  name: string
  defaultValue?: any
}

export default function ControlledInput({
  name,
  defaultValue = '',
  ...rest
}: ControllerInputProps) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => <Input {...field} {...rest} />}
    />
  )
}
