export default function MetaMask() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3589 17.9821L19.0779 18.2952L18.8128 18.4964L7.51215 16.9768H0.827148L3.21415 11.0686L7.09477 11.0918L8.03452 12.7972L11.1346 16.0153L10.733 14.0115L13.4998 14.5034L18.8128 18.4964L14.6136 14.5034L13.9241 12.596L11.4986 6.68861C11.4986 6.68861 9.6349 3.38879 9.5964 3.35181C9.6489 3.35869 13.9241 3.33633 13.9241 3.33633L14.7046 6.50973L16.4258 12.596L16.8956 14.2429L18.5625 16.1718L17.2063 14.101L18.0926 13.7286L18.517 15.606L18.5555 16.1718L20.3589 17.9821Z"
        fill="#F58C25"
      />
      <path
        d="M7.09477 11.0909L3.21415 11.0686L2.28927 10.0925L2.8729 9.70545L1.82727 9.14645L2.72152 8.90823L1.4939 8.14885L2.2289 7.83581L2.4109 6.70319L4.44965 2.96391L7.20852 0.586014L9.58852 3.35005L11.4986 6.67997L7.09477 11.0901V11.0909Z"
        fill="#81491F"
      />
      <path
        d="M0.827148 16.9768H7.51915L9.34615 19.3753C9.34615 19.3753 4.79877 20.7238 2.51677 21.4092C1.93315 19.8819 1.37927 18.4293 0.827148 16.9768Z"
        fill="#E48027"
      />
      <path
        d="M18.8206 6.85205L19.3587 7.46265L18.965 7.92533L19.5101 8.31233L19.1163 8.82661L19.2073 9.63845L18.5327 10.4279L14.7046 6.50977L20.806 1.5407L18.8206 6.85205Z"
        fill="#81491F"
      />
      <path
        d="M18.8137 18.4964L18.8364 19.979L16.6612 21.0592L9.33915 19.3822C9.33915 19.3822 9.28578 19.42 9.33915 19.3822C12.4087 19.0924 18.8137 18.4964 18.8137 18.4964Z"
        fill="#D9C6B7"
      />
      <path
        d="M20.8059 1.5407L14.7046 6.50978L13.9241 3.33638L20.8059 1.5407Z"
        fill="#E48027"
      />
      <path
        d="M14.7046 6.50963L18.5248 10.4278L18.3656 12.4617L16.4257 12.5958L14.7046 6.50963Z"
        fill="#E57F24"
      />
      <path
        d="M9.33911 19.3743L16.6602 21.0513L18.8355 19.9703L20.3291 19.8439L19.1916 21.3042L16.1072 21.5794L9.33911 19.3743Z"
        fill="#C7B7AB"
      />
      <path
        d="M9.33817 19.3753L7.51904 16.9759L11.1345 16.0153L9.33817 19.3753Z"
        fill="#D76F21"
      />
      <path
        d="M20.3291 19.844C19.8286 19.8887 19.336 19.9334 18.8355 19.9713L18.8127 18.4886L19.0779 18.2874L20.3589 17.9821C20.3589 17.9821 20.7911 18.2796 21.1621 18.4886C20.8672 18.9651 20.5934 19.4045 20.3291 19.844Z"
        fill="#2A2626"
      />
      <path
        d="M16.4248 12.596L18.3656 12.4618L18.1004 13.7286L17.3654 12.8643L17.2062 14.101L18.5624 16.1719L16.8956 14.2351L16.4257 12.596H16.4248Z"
        fill="#D66F21"
      />
      <path
        d="M7.09473 11.0908L11.4986 6.68074L13.9241 12.5958L8.03535 12.7971L7.09473 11.0908Z"
        fill="#E47E25"
      />
      <path
        d="M9.33911 19.3753L11.1355 16.0153L18.8136 18.4964L9.33911 19.3753Z"
        fill="#E47F26"
      />
      <path
        d="M8.0354 12.7972L13.9241 12.596L13.4998 14.5035L12.5075 12.9985L10.7339 14.0116L8.0354 12.7972Z"
        fill="#D66F21"
      />
      <path
        d="M13.4998 14.5035L13.9241 12.596L14.6136 14.5035L18.8136 18.4965C18.8136 18.4965 14.7125 15.4194 13.4998 14.5035Z"
        fill="#E47F25"
      />
      <path
        d="M13.4999 14.5034L10.7332 14.0115L12.5068 12.9984L13.4999 14.5034Z"
        fill="#32404E"
      />
      <path
        d="M10.7339 14.0114L11.1355 16.0152L8.0354 12.7979L10.7339 14.0123V14.0114Z"
        fill="#E57F25"
      />
      <path
        d="M17.2063 14.101L17.3655 12.8643L18.1005 13.7278L17.2063 14.101Z"
        fill="#38424D"
      />
      <path
        d="M18.1006 13.7286L18.3657 12.4618L18.6457 13.8103L18.525 15.606L18.1006 13.7286Z"
        fill="#E57F24"
      />
    </svg>
  )
}
